import ReactDOM from 'react-dom';
import React, {Component} from 'react';
import moment from 'moment';

import { Button, Col, Dropdown, Form, Icon, Input, Layout, Menu, message, Modal, Row, Spin, Typography, Upload } from 'antd';
import { isLoggedIn, login, logout } from './auth';

import './App.css';
import Axios from 'axios';
const { Dragger } = Upload;
const { Content, Header } = Layout;
const { Paragraph, Text, Title } = Typography;

const AdminPortal = props => {
  const { files, getConfigFiles } = props;

  const downloadConfig = filename => {
    const locale = filename && filename.indexOf("en-EN") > -1 ? "en-EN" : "sv-SE";
    const queryString = filename ? `?file=${filename}` : '';
  
    fetch(`/api/configuration/config-file/${queryString}`)
      .then(resp => resp.blob())
      .then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = `config.${locale}.yaml`;
        a.click();
      })
      .catch(console.log);
  }
  
  const uploadConfig = {
    name: 'file',
    accept: '.yaml',
    action: '/api/configuration/config-file/',
    headers: {
      authorization: 'authorization-text',
    },
    showUploadList: {showPreviewIcon: false, showDownloadIcon: false, showRemoveIcon: false},
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
        getConfigFiles()
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  
  const filesMenu = locale => {
    const formattedList = [];

    const toTime = datetime => {
      return moment(datetime, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm')
    }

    if (files === 'undefined') {
      return (<Menu><Menu.Item><Spin/></Menu.Item></Menu>);
    }
    
    if (files[locale].length === 0) {
      return (<Menu><Menu.Item disabled>Bara nuvarande finns tillgänglig</Menu.Item></Menu>)
    }

    for (let i = 0; i < files[locale].length; i++) {
      let text;
      if (files[locale][i] === `texts.${locale}.yaml`) {
        text = 'Nuvarande';
      } else if (i === files[locale].length - 1) {
        text = 'Äldsta';
      } else {
        text = `${toTime(files[locale][i + 1].split('-')[0])}`;
      }      
      formattedList.push({filename: files[locale][i], text});
    }

    return (
      <Menu onClick={event => downloadConfig(event.key)}>
        {formattedList.map(file => (
          <Menu.Item key={file.filename}>
            {file.text}
          </Menu.Item>
        ))}
      </Menu>
    )
  }

  return (
    <div className="Content" style={{ background: '#fff', margin: '30px', padding: '20px', height: 'auto' }}>
      <Title>Konfigurationstexter</Title>
      <Paragraph>
        Följ guiden för att ändra i konfigurationen
      </Paragraph>
      <Row>
        <Col span={2}>
          <div className='mkvd-circle'><h1>1</h1></div>
        </Col>
        <Col span={20}>
          <Paragraph>
            Börja med att ladda ner nuvarande konfiguration så du har något att utgå ifrån.
          </Paragraph>
          <Paragraph>
            <Dropdown.Button onClick={() => downloadConfig()} overlay={() => filesMenu("sv-SE")}>
              Ladda ner svenska
            </Dropdown.Button>
          </Paragraph>
          <Paragraph>
            <Dropdown.Button onClick={() => downloadConfig("texts.en-EN.yaml")} overlay={() => filesMenu("en-EN")}>
              Ladda ner engelska
            </Dropdown.Button>
          </Paragraph>
        </Col>)
      </Row>
      <br/>
      <Row>
        <Col span={2}>
          <div className='mkvd-circle'><h1>2</h1></div>
        </Col>
        <Col span={20}>
          <div style={{ width: '400px' }}>
            <Paragraph>
              Öppna filen i en texteditor (t.ex. <a href="https://notepad-plus-plus.org/downloads/">Notepad++</a>).
            </Paragraph>
            <Paragraph>
              Formateringen i filen är viktig, så var uppmärksam på hur de andra raderna ser ut, och gör ändringarna enligt samma mönster.
              (Det är också vikigt att använda <Text strong>mellanslag</Text> istället för tab, detta är inställt default i Notepad++)
            </Paragraph>
            <Paragraph>
              Det är också viktigt att aldrig ändra på några <i>Id'n</i>!
            </Paragraph>
          </div>
        </Col>
      </Row>
      <br/>
      <Row>
        <Col span={2}>
          <div className='mkvd-circle'><h1>3</h1></div>
        </Col>
        <Col span={20}>
          Avsluta med att ladda upp den uppdaterade konfigurationen. Om det inte finns några fel i filen kommer uppdateringen ske direkt.
          <div style={{ width: '400px' }}>
            <Dragger {...uploadConfig}>
              <p className="ant-upload-drag-icon">
                <Icon type="inbox" />
              </p>
              <p className="ant-upload-text">Klicka eller dra hit en uppdaterad konfiguration</p>
            </Dragger>
          </div>
        </Col>
      </Row>
    </div>
  )
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: null,
      email: '',
      password: ''
    };
  }

  getConfigFiles = () => {
    Axios.get('/api/configuration/config-files/')
      .then(response => {
        const files = {'en-EN': [], 'sv-SE': []}
        if (response.data.files) {
          for (const file of response.data.files) {
            if (file.indexOf('en-EN') > -1) { files['en-EN'].push(file) }
            if (file.indexOf('sv-SE') > -1) { files['sv-SE'].push(file) }
          }
        }
        files['en-EN'].sort().reverse().splice(5)
        files['sv-SE'].sort().reverse().splice(5)
        console.log('Refreshed config files:', response.data.files, files);
        this.setState({ files })
      });
  }

  async componentDidMount() {
    const loggedIn = await isLoggedIn();
    this.getConfigFiles();
    this.setState({ loggedIn: loggedIn });
  }

  login = () => {
    login(this.state.email, this.state.password)
      .then(res => {
        console.log('Logged in! Session cookie saved');
        this.setState({ loggedIn: true });
      })
      .catch(err => {
        if (err.response.status === 403) {
          message.error('Failed to login (unknown user or bad password)');
        } else {
          message.error(`Failed to login`);
        }
        this.setState({ loggedIn: false });
      });
  }

  logout = () => {
    logout()
      .then(res => {
        console.log('Logged out!');
        this.setState({ loggedIn: false });
      });
  }

  LoginModal = () => {
    return (
      <Modal title='Du måste logga in!' visible={ this.state.loggedIn === false } onOk={() => this.login()} style={{ width: '500px' }}>
        <Form layout='inline'>
          <Form.Item>
            <Input placeholder='e-mail' value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })}/>
          </Form.Item>
          <Form.Item>
            <Input placeholder='lösenord' value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} type='password'/>
          </Form.Item>
        </Form>
      </Modal>
    )
  }

  render() {
    return (
      <Layout style={{ height: '100vh' }}>
        <this.LoginModal />
        <Header style={{ height: '158px', padding: '0' }}>
          <div className="logo">
            <div className="headerContainer">
              <Button style={{ float: 'right' }} onClick={() => this.logout()}>Logga ut</Button>
            </div>
          </div>
        </Header>
        <Content>
          { this.state.loggedIn === null && <Spin size='large'/> }
          { this.state.loggedIn !== null && <AdminPortal files={ this.state.files } getConfigFiles={ this.getConfigFiles } /> }
        </Content>
      </Layout>
    );
  }
}

export default App;

ReactDOM.render(
  <App/>,
  document.getElementById('root')
);