import axios from 'axios';

export async function isLoggedIn() {
    console.log('Checking session cookie');
    try {
        const resp = await axios.get('api/users/active-session/');

        if (resp.status === 200) {
            console.log('session cookie is active (logged in)');
            return true;
        }
    } catch (error) {
        console.log('session cookie is inactive');
        return false;
    }
}

export function login(username, password) {
    return axios.post('api/users/login/', { user: username, password: password })
}

export function logout() {
    return axios.get('api/users/logout/')
}